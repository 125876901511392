import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      apiUrl: "https://badmin.pae-gobernova.com.mx/api/",
      //apiUrl: "http://localhost:3001/api/",
      gobernovaPhoneNumber: "7227957040",
      templateID: "d-8f3e664e61464da5ab45abad9b3a0e05",
      gobernovaPaeEmail: "pae@gobernova.com.mx",
      gobernovaDireccionGeneralEmail: "direcciongeneral@gobernova.com.mx",
      gobernovaInformesEmail: "informes@gobernova.com.mx",
      gobernovadirecciondeoperacionesEmail:
        "direcciondeoperaciones@gobernova.com.mx",
      gobernovaGmail: "sistemadeintegracionpae@gmail.com",
      gobernovaEmailFrom: "direccioncomercial@gobernova.com.mx",
      //gobernovaEmailFrom: 'lucero.garcia@grupointegra.dev',
      count: localStorage.getItem("count")
        ? parseInt(localStorage.getItem("count"))
        : 0,
      user: localStorage.getItem("user") ? localStorage.getItem("user") : "",
    };
  },
  mutations: {
    increment(state) {
      state.count++;
      localStorage.setItem("count", state.count);
    },
    reset(state) {
      state.count = 0;
      localStorage.setItem("count", state.count);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", user);
    },
    clearUser(state) {
      state.user = "";
      localStorage.removeItem("user");
    },
  },
});

export default store;
