<template>
  <v-app-bar :color="getStoreUser == '' ? '#b61c34' : getStoreUser.color">
    <v-toolbar-title class="text-uppercase ml-0 pl-4" @click="gotohome">
      Sistema para la gestión del PAE
    </v-toolbar-title>
    <template v-if="getStoreUser != ''">
      <v-avatar class="mr-4">
        <v-img
          :src="getStoreUser.image_path"
          alt="image"
          style="max-width: 40px; height: auto"
        />
      </v-avatar>
      <span class="text-uppercase">
        {{ getStoreUser.instance_name }}
      </span>
      <v-btn icon @click="logout">
        <v-icon :icon="mdi.out" />
        <v-tooltip activator="parent" location="bottom"
          >Cerrar sesión</v-tooltip
        >
      </v-btn>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      logged_user: null,
      fa: {
        out: "fa-solid fa-arrow-right-from-bracket",
      },
      mdi: {
        out: "mdi mdi-logout",
      },
    };
  },
  computed: {
    getStoreUser() {
      const userState = this.$store.state.user;
      if (userState != "") {
        const user = JSON.parse(userState);
        user.image_path =
          this.$store.state.apiUrl + "images/" + user.image_path;
        return user;
      } else {
        return "";
      }
    },
  },
  mounted() {},
  methods: {
    logout() {
      this.$store.commit("clearUser");
      sessionStorage.removeItem("filter_user_id");
      sessionStorage.removeItem("filter_isntance_name");
      sessionStorage.removeItem("filter_evaluation_id");
      this.$router.push({ name: "Login" });
    },
    gotohome() {
      if (this.getStoreUser != "") {
        if (this.getStoreUser.boolean_admin_permissions === 1) {
          this.$router.push({ name: "EvaluatedInstances" });
        } else if (this.getStoreUser.boolean_admin_permissions === 0) {
          this.$router.push({ name: "EvaluationsList" });
        }
      }
    },
  },
};
</script>
